import {
    LogLevel,
    BillingPeriod as CommonBillingPeriod,
    BillingReport as CommonBillingReport,
    Product,
    LicenseEntitlements,
    ProductId,
} from 'common/api/v1/types'

export enum AuditOperation {
    create = 'create',
    delete = 'delete',
    update = 'update',
    login = 'login',
    logout = 'logout',
}

export interface AuditRefData {
    [prop: string]: string | number | boolean | undefined
}
export interface AuditLog {
    id: string
    operation: AuditOperation
    entity: string
    entityId?: string
    entityName: string
    // pathname: string
    // query: string
    userId: string
    username: string
    clientIp?: string // Only visible for super user
    // request?: string
    // response?: string
    error?: string
    executionTimeMs?: number
    createdAt: Date
    updatedAt?: Date
}

export enum Role {
    invoiceOnly = 'invoice only',
    licenseAdmin = 'license admin', // Cannot create customers
    admin = 'admin', // Can create customers
    super = 'super',
}

export interface GlobalSettings {
    logLevel: LogLevel
}

export interface LoginRequest {
    username: string
    password: string
}

export interface LoginResult {
    user: User
}

export interface LogoutResponse {
    success: true
}

export interface BatchLicenseOperation {
    licenseIds: string[]
}

export interface BatchRevokeLicenses extends BatchLicenseOperation {}
export interface BatchReEnableLicenses extends BatchLicenseOperation {}
export interface BatchDeleteLicenses extends BatchLicenseOperation {}

export interface BatchResult<ID extends string = string> {
    succeeded: ID[]
    failed: ID[]
    // This is a hack to make the audit log populate the entity_name column for
    // batch operations with a comma-separated list of names
    names?: string[]
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of users can be filtered.
 */
export interface UserFilter {
    userId?: string
    username?: string
}

export enum ListUserSortableField {
    userName = 'userName',
    role = 'role',
}

export interface User {
    id: string
    username: string
    role: Role
    managedBy: UserManagementSystem
}

export enum UserManagementSystem {
    billingServer = 'billingServer',
    azure = 'azure',
}

export type NewUser = Pick<User, 'username' | 'role'> & {
    password: string
}
export type UserUpdate = Partial<NewUser>

export interface Customer {
    id: string
    name: string
    billingType: CustomerBillingType
    billingStartsAt: Date
    comment: string
    jeevesCustomerNumber: string
}

// These are also stored in the billing_type table in the database
export enum CustomerBillingType {
    usageBased = 'Usage based',
    rtuBased = 'RTU based',
    other = 'Other',
    none = 'None',
}

export type NewCustomer = Pick<
    Customer,
    'name' | 'jeevesCustomerNumber' | 'billingStartsAt' | 'comment' | 'billingType'
>
export type CustomerUpdate = Partial<NewCustomer>

export enum ListCustomerSortableField {
    id = 'id',
    name = 'name',
}

export interface CustomerFilter {
    customerId?: string
    name?: string
    searchName?: string
}

export enum LicenceInvalidationReason {
    // Revoked by admin
    revoked = 'revoked',
}

export const LICENSE_TYPES = {
    trial: {
        id: 'c794cc49-b0d1-46ed-afd3-e3622745d8ab',
        name: 'Trial',
    },
    usage: {
        id: '1b8752a6-805f-4175-a604-6329358f341d',
        name: 'Usage based',
    },
    rtu: {
        id: '1b410499-bc67-41fc-873c-0701f82d1604',
        name: 'RTU based',
    },
    unmetered: {
        id: '9cd69dbd-f824-466b-a2a8-d0c140095805',
        name: 'Unmetered',
    },
} as const

export type LicenseType = (typeof LICENSE_TYPES)[keyof typeof LICENSE_TYPES]
export type LicenseId = LicenseType['id']

// License is part of the API and will be visible to users
export interface License {
    id: string
    customer: Pick<Customer, 'id' | 'name'>
    purpose: string
    domain?: string

    product: Product['id']
    licenseType: LicenseType['id']

    licenseKey: string
    createdAt: Date
    /// When this license was last used
    activatedAt: Date | null
    invalidatedAt: Date | null
    invalidationReason: LicenceInvalidationReason | null

    entitlements?: LicenseEntitlements

    dockerUsername?: string
    dockerToken?: string
}

// LicenseDbo matches the database-object and should not be involved in the API
export interface LicenseDbo {
    id: string
    customer: Pick<Customer, 'id' | 'name'>
    purpose: string
    domain?: string

    product: Product['id']
    licenseType: LicenseType['id']

    licenseKey: string
    createdAt: Date
    /// When this license was last used
    activatedAt: Date | null
    invalidatedAt: Date | null
    invalidationReason: LicenceInvalidationReason | null

    entitlements?: LicenseEntitlements

    dockerUsername?: string
    dockerToken?: string
    dockerTokenUuid?: string
}

export interface NewLicense {
    // Customer id
    customer: string
    purpose: string
    product: string
    licenseType: string
    domain?: string

    entitlements?: LicenseEntitlements
}

// Used only for public route /api/license/:id
export interface PublicLicense {
    id: string
    domain?: string
    installations: LicenseInstallation[]
}
type LicenseInstallation = Pick<Installation, 'id' | 'host' | 'createdAt' | 'lastReportAt'>

export interface LicenseFilter {
    customerId?: string
    licenseKey?: string
    customerName?: string
    searchName?: string
    productId?: string
    licenseIds?: string[]
    excludeRevoked?: boolean
}

export enum ListLicenseSortableField {
    createdAt = 'created_at',
    activatedAt = 'activated_at',
    invalidatedAt = 'invalidated_at',
    customerId = 'customer',
    customerName = 'name',
}

export interface Installation {
    id: string
    host: string
    releaseTag: string
    deploymentProvider: string
    license: Pick<License, 'id' | 'licenseKey' | 'purpose' | 'entitlements'>
    customer: Pick<Customer, 'id' | 'name'>
    product: Pick<Product, 'id' | 'name'>
    createdAt: Date
    updatedAt: Date
    lastReportAt: Date | null
    lastReportedPeriodStartAt: Date | null
    deleted: boolean
}

export interface CreatedInstallation extends Installation {
    secret: string
    entitlementsToken: string // JWT of the entitlements
    dockerUsername?: string
    dockerAccessToken?: string
}

export interface NewInstallation {
    host?: string
    licenseKey: string
    releaseTag: string
    deploymentProvider: string
    product?: ProductId // This should be mandatory, but is optional for backwards compatibility
    publicIps?: string[]
}

export interface UpdateInstallationLicenseRequest {
    secret: string
    host: string
    newLicenseKey: string
}

export interface UpdateInstallationLicenseResponse {
    secret: string
    entitlementsToken: string // JWT of the entitlements for the installation
}

export interface ValidateInstallationRequest {
    secret: string
    releaseTag: string
    deploymentProvider: string
}

export interface ValidateInstallationResponse {
    entitlementsToken: string // JWT of the entitlements for the installation
    docker?: {
        username: string
        accessToken: string
    }
}

export interface InstallationFilter {
    installationId?: string
    host?: string
    customerId?: string
    searchName?: string
    licenseKey?: string
    releaseTag?: string
    deploymentProvider?: string
    excludeDeleted?: boolean
}

export enum ListInstallationSortableField {
    id = 'id',
    host = 'host',
    customerName = 'customer_name',
    releaseTag = 'release_tag',
    deploymentProvider = 'deployment_provider',
}

export interface BuildInfo {
    buildTime: Date
    release: string // Release version, i.e. R3.19.0
    commit: string // Git commit hash
    build: string // Pipeline id
}

export interface AuditFilter {
    method?: string
    error?: boolean
    pathname?: string
    operation?: string
    username?: string
    entity?: string
    entityId?: string
    entityName?: string
    fromDate?: Date
    toDate?: Date
}

export interface BillingHour {
    start_at: Date
    ingress_records: Array<bigint> | null
    egress_records: Array<bigint> | null
    egress_credits: number | null
    ingress_credits: number | null
    stream_credits: number | null
    max_at: Date | null
    created_at: Date | null
}

export interface StreamRecord {
    id: bigint
    tags_id: bigint
    last_sample_at: Date
    start_at: Date
    total_bytes: bigint | null
    egress_credits: number | null
    ingress_credits: number | null
    duration_seconds: number | null
    stop_at: Date | null
}
export interface StreamRecordTags {
    id: bigint
    stream_id: number
    input_tags_id: bigint
    output_tags_id: bigint | null
    created_at: Date | null
}
export interface ApplianceTags {
    id: bigint
    appliance_id: string | null
    appliance_name: string | null
    appliance_type: string | null
    region_name: string | null
    created_at: Date | null
}
export interface InputTags {
    id: bigint
    group_tags_id: bigint
    appliance_tags_id: bigint
    secondary_appliance_tags_id: bigint | null
    channel_id: number
    input_id: string
    input_name: string
    input_port_mode: string | null
    input_port_count: number | null
    input_merge: boolean | null
    input_ingress_count: number | null
    created_at: Date | null
}
export interface OutputTags {
    id: bigint
    group_tags_id: bigint
    appliance_tags_id: bigint
    secondary_appliance_tags_id: bigint | null
    output_id: string | null
    output_name: string | null
    output_region: string | null
    output_port_mode: string | null
    output_port_count: number | null
    output_egress_count: number | null
    created_at: Date | null
}

export interface GroupTags {
    id: bigint
    group_id: string
    group_name: string
    created_at: Date | null
}

export interface BillingHourlySlice {
    billingHours: BillingHour[]
    streamRecords: StreamRecord[]
    streamRecordTags: StreamRecordTags[]
    applianceTags: ApplianceTags[]
    groupTags: GroupTags[]
    inputTags: InputTags[]
    outputTags: OutputTags[]
}

export type BillingPeriod = CommonBillingPeriod & {
    customer: Pick<Customer, 'id' | 'name' | 'jeevesCustomerNumber' | 'billingType'>
    licenseId: License['id']
    licenseKey: License['licenseKey']
    licenseType: License['licenseType']
    host: Installation['host']
}

export type BillingReport = Pick<CommonBillingReport, 'startDate' | 'endDate' | 'summary'> & {
    periods: BillingPeriod[]
}
