import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Grid, Link, Paper } from '@mui/material'
import { GetApp } from '@mui/icons-material'

import { addMonthUTC, dateToYearMonthDayString, endOfMonthUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import type { ListResult } from 'common/api/v1/types'
import { findLicenseTypeById } from 'common-billing-server/index'
import type { BillingPeriod, Customer } from 'common-billing-server/types'

import { useBillingApi } from '../../api/billing/billing-context'
import { Path } from '../../routes/path'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable, Row } from '../common/Table'
import { AutoComplete } from '../common/AutoComplete'
import { DateRangePicker } from '../common/DateRangePicker'
import { ListPageToolbar } from '../common/ListPageToolbar'

type BillingTableRow = BillingPeriod & Pick<Row, 'id'>

const columns: Column<BillingTableRow, ''>[] = [
    { title: 'Customer name', valueForColumn: (p) => p.customer.name },
    { title: 'Jeeves id', valueForColumn: (p) => p.customer.jeevesCustomerNumber },
    { title: 'License key', valueForColumn: (p) => p.licenseKey },
    { title: 'License type', valueForColumn: (p) => findLicenseTypeById(p.licenseType)?.name },
    { title: 'Host', valueForColumn: (p) => p.host },
    { title: 'From', valueForColumn: (p) => dateToYearMonthDayString(p.startDate) },
    { title: 'To', valueForColumn: (p) => dateToYearMonthDayString(p.endDate) },
    { title: 'Ingress credits', valueForColumn: (p) => p.ingressCredits },
    { title: 'Egress credits', valueForColumn: (p) => p.egressCredits },
    { title: 'Total credits', valueForColumn: (p) => p.totalCredits },
]

export const BillingPage = () => {
    const billingApi = useBillingApi()
    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const { navigate } = useStatefulNavigate()
    const navigateToLicenseBilling = ({ licenseId, startDate, endDate }: BillingTableRow) =>
        navigate(
            `${
                Path.billingDetails
            }?licenseId=${licenseId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        )
    const [customer, setCustomer] = useState<Customer | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const customerId = searchParams.get('customerId') ?? undefined
    const startDateUrlParam =
        searchParams.get('startDate') ?? startOfMonthUTC(addMonthUTC(new Date(), -1)).toISOString()
    const endDateUrlParam = searchParams.get('endDate') ?? endOfMonthUTC(new Date()).toISOString()
    const { minDate, maxDate } = {
        minDate: addMonthUTC(new Date(endDateUrlParam), -4),
        maxDate: addMonthUTC(new Date(startDateUrlParam), 4),
    }
    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    const setDate = (key: 'startDate' | 'endDate', value: Date | null) => {
        if (!value || !value.getTime()) return

        searchParams.set(key, value.toISOString())
        setSearchParams(searchParams, { replace: true })
        tableRef.current?.refreshData()
    }

    const fetchBillings = async ({
        skip,
        limit,
    }: {
        skip?: number
        limit?: number
    }): Promise<ListResult<BillingTableRow>> => {
        try {
            const res = await billingApi.listBillings(
                { skip, limit },
                {
                    customerId,
                    startDate: startDateUrlParam,
                    endDate: endDateUrlParam,
                }
            )
            return {
                ...res,
                items: res.items
                    .map((p) => ({
                        ...p,
                        id: `${p.licenseId}-${p.host}-${p.startDate.toISOString()}`,
                    }))
                    .sort((a, b) => a.customer.name.localeCompare(b.customer.name)),
            }
        } catch (error) {
            addFailedOperation({
                id: 'get-billing',
                message: 'Failed fetching billing',
            })
            throw error
        }
    }

    const changeCustomer = (c: Customer | null) => {
        setCustomer(c)
        c ? searchParams.set('customerId', c.id) : searchParams.delete('customerId')
        setSearchParams(searchParams, { replace: true })
        tableRef.current?.refreshData()
    }

    function makeBillingReportsCsvUrl(customer: Customer | null, startDateString: string, endDateString: string) {
        const searchParams = new URLSearchParams()
        if (customer) searchParams.set('customerId', customer.id)
        searchParams.set('startDate', startDateString)
        searchParams.set('endDate', endDateString)
        searchParams.set('format', 'csv')
        searchParams.set('q', JSON.stringify({ skip: 0, limit: 10000 })) // TODO: Don't hardcode
        return `/api/billing?${searchParams.toString()}`
    }

    return (
        <>
            <ListPageToolbar
                title={'Billing'}
                numberOfSelectedItems={0}
                selectedActions={
                    [
                        /* noop */
                    ]
                }
                actions={
                    [
                        /* noop */
                    ]
                }
            />

            <Paper style={{ margin: '20px', padding: '10px' }}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                        <AutoComplete<Customer>
                            placeholder={`Customer`}
                            value={customer}
                            isRequired
                            onValueSelected={(c) => changeCustomer(c)}
                            formatSelectedValue={(customer) => customer.name}
                            isClearable
                            api={(query) =>
                                billingApi.listCustomers({ ...query, filter: { customerId, searchName: query.filter } })
                            }
                            dataTestId={'billing-customer'}
                        />
                    </Grid>

                    <Grid item xs="auto">
                        <DateRangePicker
                            minDate={minDate}
                            startDateString={startDateUrlParam}
                            endDateString={endDateUrlParam}
                            maxDate={maxDate}
                            onChange={setDate}
                        />
                    </Grid>

                    <Grid item xs="auto">
                        <Link
                            underline="none"
                            href={makeBillingReportsCsvUrl(customer, startDateUrlParam, endDateUrlParam)}
                        >
                            <Button variant="outlined">
                                <span>Download</span>
                                <GetApp />
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>

            <Paper>
                <DataFetchingPaginatedTable<BillingTableRow, '', Record<string, never>>
                    myRef={tableRef}
                    api={fetchBillings}
                    columns={columns}
                    onRowClicked={navigateToLicenseBilling}
                    emptyRowsMessage={'No billing found'}
                />
            </Paper>

            {errorBanner}
        </>
    )
}
