import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Link, Paper, Toolbar, Typography } from '@mui/material'
import { GetApp } from '@mui/icons-material'

import { useBillingApi } from '../../api/billing/billing-context'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { BillingSummary } from '../common/BillingSummary'
import { FlexFiller } from '../../theme/common-styles'
import { findLicenseTypeById } from 'common-billing-server/index'
import type { BillingReport } from 'common-billing-server/types'
import { DateRangePicker } from '../common/DateRangePicker'

export const BillingDetailsPage = () => {
    const billingApi = useBillingApi()
    const [billingReport, setBillingReport] = useState<BillingReport | undefined>()
    const customer = billingReport?.periods[0]?.customer
    const licenseTypeName = billingReport?.periods[0]?.licenseType
        ? findLicenseTypeById(billingReport.periods[0].licenseType)?.name
        : undefined
    const [isLoading, setIsLoading] = useState(false)
    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    const [searchParams, setSearchParams] = useSearchParams()
    const licenseId = searchParams.get('licenseId')
    const startDateUrlParam = searchParams.get('startDate')
    const endDateUrlParam = searchParams.get('endDate')

    const setDate = (key: 'startDate' | 'endDate', value: Date | null) => {
        if (!value || !value.getTime()) return

        searchParams.set(key, value.toISOString())
        setSearchParams(searchParams, { replace: true })
    }

    useEffect(() => {
        async function fetchBillingReport() {
            const operationId = 'fetch-billing-report'
            if (!licenseId) return
            if (!startDateUrlParam) return
            if (!endDateUrlParam) return

            removeFailedOperation(operationId)
            setBillingReport(undefined)
            setIsLoading(true)
            try {
                const billingReport = await billingApi.getLicenseBillingReport(licenseId, {
                    startDate: new Date(startDateUrlParam).toISOString(),
                    endDate: new Date(endDateUrlParam).toISOString(),
                })
                setBillingReport(billingReport)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching billing report',
                    error,
                    retryFn: () => void fetchBillingReport(),
                })
            }
            setIsLoading(false)
        }

        void fetchBillingReport()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseId, startDateUrlParam, endDateUrlParam])

    function makeBillingReportPdfUrl(licenseId: string, startDate: string, endDate: string) {
        const searchParams = new URLSearchParams()
        searchParams.set('startDate', startDate)
        searchParams.set('endDate', endDate)
        searchParams.set('format', 'pdf')
        return `/api/license/${licenseId}/billing/report?${searchParams.toString()}`
    }

    return (
        <>
            <Toolbar style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                        Billing details
                    </Typography>

                    {customer && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6" component="div">
                                Customer:
                            </Typography>
                            <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                {customer.name}
                            </Typography>

                            <Typography variant="h6" component="div" style={{ marginLeft: 20 }}>
                                Jeeves customer number:
                            </Typography>
                            <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                {customer.jeevesCustomerNumber}
                            </Typography>
                        </div>
                    )}
                </div>

                <FlexFiller />
            </Toolbar>

            {startDateUrlParam && endDateUrlParam && (
                <Paper sx={{ margin: '20px', paddingTop: 1 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ marginLeft: 20 }}>
                            License ID:
                        </Typography>
                        <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                            {licenseId}
                        </Typography>
                        {licenseTypeName && (
                            <>
                                <Typography variant="h6" component="div" style={{ marginLeft: 20 }}>
                                    License type:
                                </Typography>
                                <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                    {licenseTypeName}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
                        <DateRangePicker
                            startDateString={startDateUrlParam}
                            endDateString={endDateUrlParam}
                            onChange={setDate}
                        />
                        {isLoading && <CircularProgress style={{ marginLeft: 12, padding: '8px' }} />}
                        {licenseId && (
                            <Link
                                underline="none"
                                href={makeBillingReportPdfUrl(licenseId, startDateUrlParam, endDateUrlParam)}
                            >
                                <Button variant="outlined">
                                    <span>Download</span>
                                    <GetApp />
                                </Button>
                            </Link>
                        )}
                    </div>
                    {billingReport && <BillingSummary billing={billingReport} />}
                </Paper>
            )}

            {errorBanner}
        </>
    )
}
